import React from "react"
import { graphql } from "gatsby"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import { Box, Text, Container, Grid } from "@theme-ui/components"
import Layout from "../components/layout"
import { getReferencePath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageGallery from "../components/blocks/imageGallery"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import PageHero from "./pageHero"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"

const Reference = ({ data: { page, references } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  // console.log(page)
  const i18nPaths = page._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value: getReferencePath(page, path.locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths} color="dark">
      <HelmetDatoCms
        seo={FilterMetaTagDescription(page.seoMetaTags)}
        favicon={favicon}
      >
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} color="dark" />
      <Box>
        <Container>
          <Grid columns={["1fr", "1fr 1fr"]}>
            <Box
              sx={{ strong: { color: "primary", fontWeight: "500" } }}
              dangerouslySetInnerHTML={{ __html: page.shortDescription }}
            />
            <Box>
              <Box
                sx={{
                  mb: [5],
                  a: {
                    color: "inherit",
                    textDecoration: "underline",
                  },
                  ".gatsby-image-wrapper": { width: "100%", mb: [2, 5] },
                }}
              >
                <GatsbyImage image={page.heroImage.gatsbyImageData} />
                <StructuredText
                  data={page.body}
                  customRules={[
                    renderRule(
                      isHeading,
                      ({ adapter: { renderNode }, node, children, key }) => {
                        return renderNode(
                          () => {
                            return (
                              <Text
                                as={`h${node.level}`}
                                variant={`h${node.level}`}
                                sx={{ mb: 3 }}
                              >
                                {children}
                              </Text>
                            )
                          },
                          { key },
                          children
                        )
                      }
                    ),
                    renderRule(
                      isParagraph,
                      ({ adapter: { renderNode }, node, children, key }) => {
                        return renderNode(
                          () => {
                            return (
                              <Text as="p" mb={3} variant="article">
                                {children}
                              </Text>
                            )
                          },
                          { key },
                          children
                        )
                      }
                    ),
                  ]}
                  renderBlock={({ record }) => {
                    // console.log(record)
                    switch (record.__typename) {
                      case "DatoCmsImageGallery":
                        return (
                          <Box mt={5} mb={5}>
                            <ImageGallery
                              images={record.images}
                              key={record.id}
                            />
                          </Box>
                        )
                      default:
                        return null
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export default Reference

export const query = graphql`
  query ReferenceQuery($id: String!, $locale: String!) {
    page: datoCmsReference(id: { eq: $id }) {
      ...ReferenceDetails
      ...ReferenceAllSlugLocales
      ...ReferenceMeta
      meta {
        firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    references: allDatoCmsReference {
      nodes {
        ...ReferenceDetails
        ...ReferenceAllSlugLocales
        ...ReferenceMeta
        meta {
          firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
        }
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
  }

  fragment ReferenceMeta on DatoCmsReference {
    meta {
      firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
    }
  }

  fragment ReferenceAllSlugLocales on DatoCmsReference {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment ReferenceDetails on DatoCmsReference {
    id
    locale
    title
    subtitle
    slug
    model {
      apiKey
    }
    heroImage {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    ...ReferenceBody
  }

  fragment ReferenceBody on DatoCmsReference {
    shortDescription
    body {
      value
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
      }
    }
  }

  fragment ImageGallery on DatoCmsImageGallery {
    images {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
      alt
      title
    }
    model {
      apiKey
    }
  }
`
